import React, { useState, useEffect, useRef } from "react";
import { Footer } from "../components/Footer.js";
import { Spe_offer } from "../components/spe_offer.js";
import { useParams } from "react-router-dom";
import earth from "./../assets/img/earth.svg";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import ErrorSnackbar from "../components/ErrorSnackbar.js";
import LoginPopup from "../components/LoginPopup.js";
import SuccessSnackbar from "../components/SuccessSnackbar.js";
import pdf from "./../assets/img/pdf.png";

import { useNavigate } from "react-router-dom";
import {
  Products_detail_api,
  addtocart_api,
  Get_attribute_list,
  addtocart_get_api,
} from "../service/api.js";
import Head_CateHead from "../components/Head_CateHead.js";
import CartModel from "../components/CartModel.js";

const Product_detail_call = async (productId) => {
  const res = await Products_detail_api(productId);
  if (res.status === 200) {
    var data = res.data.Data;
  } else {
    // console.log(res);
  }
  return data;
};
var productId = "";
export const Product_detail = () => {
  const navigate = useNavigate();
  const { slug } = useParams();

  const [subproduct, setsubproduct] = useState([]);
  const [api_data, setapi_data] = useState([]);
  const [productUnbrandedPrices, setProductUnbrandedPrices] = useState([]);
  const [AddtoCartButton, setAddtoCartButton] = useState(true);
  const storedRoleID = localStorage.getItem("RoleID");
  const [productSetupPrice, setproductSetupPrice] = useState([]);
  const [productattributedata, setproductattributedata] = useState([]);
  const [productVariationAttributeValues, setProductVariationAttributeValues] =
    useState([]); // Add this state
  const [ExceptionError, setExceptionError] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const [openCartModal, setOpenCartModal] = useState(false)

  function handleExceptionError(res) {
    setExceptionError((ExceptionError) => [
      ...ExceptionError,
      { id: Date.now(), message: res },
    ]);
  }

  function handleExceptionSuccessMessages(res) {
    setSuccessMessages((successMessages) => [
      ...successMessages,
      { id: Date.now(), message: res.Message },
    ]);
  }

  function clearErrors(id) {
    setExceptionError((prevMessages) =>
      prevMessages.filter((msg) => msg.id !== id)
    );
  }

  function clearSuccess(id) {
    setSuccessMessages((prevMessages) =>
      prevMessages.filter((msg) => msg.id !== id)
    );
  }
  const [isProductTypeVisible, setIsProductTypeVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const api_data = await Product_detail_call(slug);
      productId = api_data.ProductID;
      await updateProductdetailData(api_data);
      if (api_data.ProductUnbrandedPrice[0].QTY) {
        setQuantityproductUnit(api_data.ProductUnbrandedPrice[0].QTY);
      }
      setapi_data(api_data);
      if (api_data.ProductType === 2) {
        setIsProductTypeVisible(true);
      }
    };
    fetchData();
  }, [productId]);

  const product_detail = api_data
    ? {
      productname: api_data.ProductName,
      file_name: api_data.ProductImage || "default.jpg",
      productdescription: api_data.ProductDescription,
      ProductTypeCheck: api_data.ProductType,
      productSKU: api_data.SKU,
      productstock: api_data.ManageStock ? "Out of Stock" : "In Stock",
      FlagName: api_data.FlagName,
      CostFlag: api_data.CostFlag,
      InformationFlag: api_data.InformationFlag,
      InformationContain: api_data.InformationContain,
      brandingtemplate: api_data.BrandingTemplate,
      subcategory: api_data.ProductCategoryMap,
      NumberRating: api_data.NumberRating,
      Rating: api_data.Rating,
      features: api_data.features,
      additional_specifications: api_data.additional_specifications,
      dimensions: api_data.dimensions,
      additional_materials: api_data.additional_materials,
      packaging: api_data.packaging,
      carton: api_data.carton,
      branding_options: api_data.branding_options,
      getinstantcourt: api_data.getinstantcourt,
      howmanypositions: api_data.howmanypositions,
      stockDescription: api_data.stockDescription,
      SKUPrefix: api_data.SKUPrefix,
      isordergrid: api_data.isordergrid,
      ProductAttribute: api_data.ProductAttribute,
      ProductAttributeValue: api_data.ProductAttributeValue,
      unbrandedpriceshow: api_data.unbrandedpriceshow,
      setupchargeshow: api_data.setupchargeshow,
      minimumqty: api_data.minimumqty
    }
    : {};

  const sizeOrder = {
    "XS": 1, "S": 2, "M": 3, "L": 4, "XL": 5, "XXL": 6,
    "XSM": 7, "SML": 8, "MED": 9, "LRG": 10, "XLG": 11,
    "2XL": 12, "3XL": 13, "4XL": 14, "5XL": 15, "6/7XL": 16, "8/9XL": 17,
    "3XS": 18, "2XS": 19,
    "6": 20, "8": 21, "10": 22, "12": 23, "14": 24, "16": 25, "18": 26, "20": 27, "22": 28, "24": 29, "26": 30,
    "28": 31, "30": 32, "32": 33, "34": 34, "36": 35, "38": 36, "40": 37,
    "Small - 600x900": 38, "Large 900x1200": 39,
    "2 Years": 40, "4": 41, "6Y": 42, "8Y": 43, "10Y": 44, "13Y": 45, "16Y": 46, "12Y": 47, "14Y": 48,
    "S-M": 49, "L-XL": 50, "X-Small": 51, "Small": 52, "Medium": 53, "Large": 54,
    "L6": 55, "L8": 56, "L10": 57, "L12": 58, "L14": 59, "L16": 60, "L18": 61, "L20": 62,
    "S/M": 63, "L/XL": 64,
    "72R": 65, "77R": 66, "82R": 67, "87R": 68, "92R": 69, "97R": 70, "102R": 71, "107R": 72, "112R": 73, "117R": 74, "122R": 75, "127R": 76
  };
  const getSizeOrder = (size) => sizeOrder[size] || 1000;


  const sortBySize = (a, b) => {
    const sizeA = a.AttributeValue.AttributeValue;
    const sizeB = b.AttributeValue.AttributeValue;

    // const orderA = sizeOrder[sizeA] || 999; // Default large number for unknown sizes
    // const orderB = sizeOrder[sizeB] || 999;
    if (sizeOrder.hasOwnProperty(sizeA) && sizeOrder.hasOwnProperty(sizeB)) {
      return getSizeOrder(sizeA) - getSizeOrder(sizeB);
    }

    // return orderA - orderB;
    return sizeA.localeCompare(sizeB);
  };
  if (product_detail.ProductAttributeValue != null && product_detail.ProductAttributeValue != undefined) {
    product_detail.ProductAttributeValue = product_detail.ProductAttributeValue.sort(sortBySize);
  }



  // console.log('product_detail', product_detail.ProductAttributeValue)

  const updateProductdetailData = async (res) => {
    try {
      // const res = await Products_detail_api(productId);
      const sub_product = res.ProductGallery;
      setsubproduct(sub_product);

      const unbrandedPrices = res.ProductUnbrandedPrice;
      unbrandedPrices.map((child_unbrandedPrices, index) => {
        if (product_detail.unbrandedpriceshow == 1) {
          unbrandedPrices[index].Price = parseFloat(child_unbrandedPrices.Price) + parseFloat(15 / parseFloat(child_unbrandedPrices.QTY))
        }
      });
      setProductUnbrandedPrices(unbrandedPrices);

      const productsetupprice = res.ProductSetupPrice;
      setproductSetupPrice(productsetupprice);

      var productattributedata = res.ProductAttribute;
      productattributedata = productattributedata.map((item) => {
        return {
          ...item,
          Value: "", // Add the 'Value' key with blank data
          Error: "", // Add the 'Error' key with blank data
        };
      });
      setproductattributedata(productattributedata);
      const productVariationAttributeValuedata =
        res.ProductVariationAttributeValue; // Change this line
      setProductVariationAttributeValues(productVariationAttributeValuedata);
    } catch (error) {
      console.error(error);
    }
  };

  const [activeSlide, setActiveSlide] = useState(-1);
  const [imagesViewed, setImagesViewed] = useState(0); // Track the number of images viewed
  // Function to handle click on previous and next buttons
  const handlePrevNext = (action) => {
    if (action === "prev") {
      setActiveSlide((prev) => (prev === 0 ? -1 : prev - 1));
      // Decrease the number of images viewed when going back
      setImagesViewed((prev) => Math.max(prev - 1, 0));
      // setIsRightButtonDisabled(false);
    } else if (action === "next") {
      setActiveSlide((prev) => (prev === subproduct.length - 1 ? 0 : prev + 1));
      // Increase the number of images viewed when going forward
      setImagesViewed((prev) => prev + 1);
      // Enable the "prev" button when the user goes forward and has viewed three images
    }
  };

  const handleSlideClick = (index) => {
    setActiveSlide(index);
  };

  const [activeTab, setActiveTab] = useState("tab1"); // State to keep track of active tab

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const [activeTabing, setActiveTabing] = useState("tab11"); // State to keep track of active tab

  const handleTabClicking = (tabIds) => {
    setActiveTabing(tabIds);
  };

  const [quantityPerUnit, setQuantityPerUnit] = useState("1"); // State to track input value

  const calculateBuyUnitIncSetups = (priceItem, setupItem, finalResult) => {
    const qtyPerUnitValue = parseFloat(
      setupItem.Default === true
        ? 1
        : setupItem.Default === false
          ? 0
          : setupItem.Default
    );
    if (isNaN(qtyPerUnitValue)) {
      return parseFloat(finalResult);
    }
    var calculatedPrice = 0;

    return calculatedPrice;
  };

  const calculateBuyUnitIncSetups2 = (
    priceItem,
    setupItem,
    markupValue,
    finalResult
  ) => {
    var calculatedPrice = 0;
    setupItem.map((item) => {
      const qtyPerUnitValue =
        item.Default === true ? 1 : item.Default === false ? 0 : item.Default;
      const unitPrice = parseFloat(item.UnitPrice);
      const setupCharge = parseFloat(item.SetupPrice);
      const unbrandedQty = parseFloat(priceItem.QTY);
      const calculatedPrice2 =
        qtyPerUnitValue * unitPrice +
        ((setupCharge) / unbrandedQty) * qtyPerUnitValue;
      calculatedPrice = calculatedPrice + calculatedPrice2;
    });
    calculatedPrice = (calculatedPrice * markupValue) / 100 + calculatedPrice + parseFloat(finalResult);
    calculatedPrice = Math.ceil(calculatedPrice * 100) / 100;
    return calculatedPrice;
  };

  // Define correspondingSetup outside of the loop const
  const correspondingSetup =
    productSetupPrice.length > 0 ? productSetupPrice[0] : null;
  //array maker from here.......

  const [calculatedPricesArray, setCalculatedPricesArray] = useState([]);
  const [calculatedPricesArray2, setCalculatedPricesArray2] = useState([]);
  const [quantityproductUnit, setQuantityproductUnit] = useState(250);

  useEffect(() => {
    calculatePrices2();
  }, [
    quantityPerUnit,
    correspondingSetup,
    calculatedPricesArray,
    calculatedPricesArray2,
    productSetupPrice,
  ]);

  const calculatePrices2 = (newValue, index, changes_status) => {
    const updatedPrices = [...productSetupPrice];
    if (changes_status == 1) {
      updatedPrices[index] = {
        ...updatedPrices[index],
        Default: newValue,
      };
      setproductSetupPrice(updatedPrices);
    }
    let newCalculatedPricesArray = [];
    let newCalculatedPricesArray2 = [];

    // if (correspondingSetup) {
    newCalculatedPricesArray = productUnbrandedPrices.map(
      (priceItem, priceIndex) => {
        var markupValue = priceItem.Retailer;
        if (storedRoleID == 0) {
          markupValue = priceItem.Admin;
        }
        if (storedRoleID == 2) {
          markupValue = priceItem.Wholesaler;
        }
        if (storedRoleID == 3) {
          markupValue = priceItem.PortalUser;
        }
        if (storedRoleID == 4) {
          markupValue = priceItem.VIPUser;
        }
        const finalResult = (
          parseFloat(priceItem.Price) +
          (parseFloat(priceItem.Price) * parseFloat(markupValue)) / 100
        ).toFixed(2);
        var calculatedPricing = calculateBuyUnitIncSetups2(
          priceItem,
          updatedPrices,
          markupValue,
          finalResult
        );
        return calculatedPricing.toFixed(2);
      }
    );
    newCalculatedPricesArray2 = productUnbrandedPrices.map(
      (priceItem, priceIndex) => {
        var markupValue = priceItem.Retailer;
        const finalResult = (
          parseFloat(priceItem.Price) +
          (parseFloat(priceItem.Price) * parseFloat(markupValue)) / 100
        ).toFixed(2);
        var calculatedPricing = calculateBuyUnitIncSetups2(
          priceItem,
          updatedPrices,
          markupValue,
          finalResult
        );
        return calculatedPricing.toFixed(2);
      }
    );
    // } else {
    //   newCalculatedPricesArray = [];
    //   newCalculatedPricesArray2 = [];
    // }
    setCalculatedPricesArray(newCalculatedPricesArray);
    setCalculatedPricesArray2(newCalculatedPricesArray2);
  };

  let minPriceToShow = 0;

  if (
    productUnbrandedPrices.length > 0 &&
    productUnbrandedPrices[0] !== undefined
  ) {
    calculatedPricesArray.map((productUnbrandedPrice, index) => {
      if (
        productUnbrandedPrices[index].QTY == quantityproductUnit ||
        quantityproductUnit > productUnbrandedPrices[index].QTY
      ) {
        minPriceToShow = productUnbrandedPrice;
      }
    });

    if (minPriceToShow === 0) {
      minPriceToShow = quantityproductUnit * calculatedPricesArray[0];
      minPriceToShow = minPriceToShow + 20;
      minPriceToShow = minPriceToShow / quantityproductUnit;

      // Convert minPriceToShow to a number, with proper checks
      if (!isNaN(minPriceToShow)) {
        minPriceToShow = parseFloat(minPriceToShow);
      } else {
        const minQty = productUnbrandedPrices
          ?.map((item) => item.QTY)
          ?.sort((a, b) => a - b)[0];
        const unbrandObj = productUnbrandedPrices?.filter(
          (item) => item.QTY >= Number(quantityproductUnit)
        );
        if (quantityproductUnit < minQty) {
          const unBratndObj = productUnbrandedPrices?.find(
            (item) => (item.QTY = minQty)
          );
          const adding = 20 / quantityproductUnit;
          var value = "";
          if (storedRoleID == 0) {
            value = unBratndObj?.Admin;
          }
          if (storedRoleID == 1) {
            value = unBratndObj.Retailer;
          }
          if (storedRoleID == 2) {
            value = unBratndObj.Wholesaler;
          }
          if (storedRoleID == 3) {
            value = unBratndObj.PortalUser;
          }
          if (storedRoleID == 4) {
            value = unBratndObj.VIPUser;
          }
          const perUnit =
            Number(unBratndObj.Price) +
            (Number(unBratndObj.Price) * Number(value)) / 100;
          minPriceToShow = Number(perUnit) + Number(adding);
        } else {
          const price = unbrandObj[0];
          var value = "";
          if (storedRoleID == 0) {
            value = price?.Admin;
          }
          if (storedRoleID == 1) {
            value = price?.Retailer;
          }
          if (storedRoleID == 2) {
            value = price?.Wholesaler;
          }
          if (storedRoleID == 3) {
            value = price?.PortalUser;
          }
          if (storedRoleID == 4) {
            value = price?.VIPUser;
          }
          const perUnit =
            Number(price?.Price) + (Number(price?.Price) * Number(value)) / 100;
          minPriceToShow = perUnit;
        }
      }
    }

  }

  const totalCost =
    parseFloat(quantityproductUnit) * parseFloat(minPriceToShow).toFixed(2); // Convert minPriceToShow to a number and then apply toFixed
  const roundedMinPrice = parseFloat(minPriceToShow).toFixed(2);

  const handleAttributeChange = (event, index) => {
    const updatedProductAttributeData = [...productattributedata];
    updatedProductAttributeData[index].Value = event.target.id;
    updatedProductAttributeData[index].Error = "";
    if (event.target.id === "") {
      updatedProductAttributeData[
        index
      ].Error = `${updatedProductAttributeData[index].Attribute.AttributeName} is required`;
    }
    setproductattributedata(updatedProductAttributeData);
  };

  // const [selectedShipping, setSelectedShipping] = useState("delivery");

  const [selectedPrint, setSelectedPrint] = useState("");
  const handlePrintSelection = (print) => {
    if (selectedPrint === print) {
      setSelectedPrint("");
      setQuantityPerUnit("");
    } else {
      setSelectedPrint(print);
      setQuantityPerUnit(quantityproductUnit);
    }
  };
  const [selectedSidePosition, setSelectedSidePosition] = useState("");
  const handlesidePosition = (position) => {
    if (selectedSidePosition === position) {
      setSelectedSidePosition("");
    } else {
      setSelectedSidePosition(position);
    }
  };
  const [selectedPrice, setSelectedPrice] = useState("");
  const [cartData, setCartData] = useState(0)

  const calculatePriceWithMarkup = (price, markup) => {
    return (
      parseFloat(price) +
      (parseFloat(price) * parseFloat(markup)) / 100
    ).toFixed(2);
  };

  const handleCalculatePrice = async (Priority) => {
    var ProductVariationID = 0;
    if (isProductTypeVisible) {
      var error = 0;
      const updatedProductAttributeData = [...productattributedata];
      updatedProductAttributeData.map((productattribute, index) => {
        if (productattribute.Value == "") {
          updatedProductAttributeData[
            index
          ].Error = `${updatedProductAttributeData[index].Attribute.AttributeName} is required`;
          error = 1;
        }
      });
      setproductattributedata(updatedProductAttributeData);
      if (error == 1) {
        return;
      }
      var matchingProductVariationIDs = findMatchingProductVariationID();
      if (matchingProductVariationIDs.length == 0) {
        handleExceptionError("Product Not found");
        return;
      }
      ProductVariationID = matchingProductVariationIDs[0];
    }

    if (Priority == 1) {
      var minimum_price = [];
      var tempsurcharge = 0;
      productUnbrandedPrices.map((productUnbrandedPrice, index) => {
        if (
          productUnbrandedPrice.QTY == quantityproductUnit ||
          productUnbrandedPrice.QTY < quantityproductUnit
        ) {
          minimum_price = productUnbrandedPrice;
        }
      });
      if (minimum_price.length == 0) {
        minimum_price = productUnbrandedPrices[0];
        tempsurcharge = 20;
      }

      var markupValue = minimum_price.Retailer;
      if (storedRoleID == 0) {
        markupValue = minimum_price.Admin;
      }
      if (storedRoleID == 2) {
        markupValue = minimum_price.Wholesaler;
      }
      if (storedRoleID == 3) {
        markupValue = minimum_price.PortalUser;
      }
      if (storedRoleID == 4) {
        markupValue = minimum_price.VIPUser;
      }

      var priceWithMarkup = calculatePriceWithMarkup(
        minimum_price.Price,
        markupValue
      );
      var priceWithMarkup2 =
        quantityproductUnit *
        (selectedPrint == "" ? 0 : productSetupPrice[0]?.SetupPrice) +
        quantityproductUnit * priceWithMarkup +
        tempsurcharge;
      priceWithMarkup2 = priceWithMarkup2.toFixed(2);
      setSelectedPrice(priceWithMarkup2);
    }
  };
  const [showQouteOption, setshowQouteOption] = useState(0);
  const handleCalculatePrice2 = async (Priority) => {
    setshowQouteOption(Priority);
  };

  const findMatchingProductVariationID = () => {
    const attributeValueIDsToFind = [];
    productattributedata.map((productattribute, index) => {
      attributeValueIDsToFind.push(parseInt(productattribute.Value));
    });

    // Initialize an object to store ProductVariationIDs as keys and their counts as values
    const productVariationIDCounts = {};

    // Iterate through the productVariationAttributeValues
    for (const item of productVariationAttributeValues) {
      const productVariationID = item.ProductVariationID;
      const attributeValueID = item.AttributeValue.AttributeValueID;

      // Check if the AttributeValueID is in the list to search for
      if (attributeValueIDsToFind.includes(attributeValueID)) {
        // Increment the count for the corresponding ProductVariationID
        if (productVariationIDCounts.hasOwnProperty(productVariationID)) {
          productVariationIDCounts[productVariationID]++;
        } else {
          productVariationIDCounts[productVariationID] = 1;
        }
      }
    }

    // Find the common ProductVariationID(s) with counts equal to the number of AttributeValueIDs to find
    const commonProductVariationIDs = Object.keys(
      productVariationIDCounts
    ).filter(
      (productVariationID) =>
        productVariationIDCounts[productVariationID] ===
        attributeValueIDsToFind.length
    );

    return commonProductVariationIDs;
  };

  const addtocart_api_call = async () => {

    var matchingProductVariationID = 0;
    if (isProductTypeVisible) {
      var matchingProductVariationIDs = findMatchingProductVariationID();
      if (matchingProductVariationIDs.length == 0) {
        handleExceptionError("Product Not found");
        return;
      }
      matchingProductVariationID = matchingProductVariationIDs[0];
    }

    // Calculate the adjusted base price
    let adjustedBasePrice = minPriceToShow;
    var AdditionalCost = 0;
    if (adjustedBasePrice < adjustedBasePrice) {
      adjustedBasePrice += 20; // Adding AdditionalCost
      AdditionalCost = 20;
    }

    // Build sorting filter based on selected options
    const cartParams = {
      ProductID: productId,
      ProductVariationID: matchingProductVariationID,
      BasePrice: adjustedBasePrice,
      QTY: quantityproductUnit,
      AdditionalCost: AdditionalCost,
      ProductSetupPrice: productSetupPrice.map((priceItem) => ({
        ProductSetupPriceID: priceItem.ProductSetupPriceID,
        QTY: quantityPerUnit, // You can replace with appropriate logic
      })),
    };

    // Call the API with the sorting filter
    setAddtoCartButton(false);
    try {
      const res = await addtocart_api(cartParams);
      getCartData()


      handleExceptionSuccessMessages(res);
      setTimeout(() => {
        if (res) {
          // navigate("/cart");
        } else {
        }
      }, 2500);
    } catch (res) {
      setAddtoCartButton(true);
      handleExceptionError(res.response.data.ErrorMessage);
      setTimeout(() => {
        if (res.response.data.ErrorMessage) {
          navigate("/signin");
        } else {
        }
      }, 2500);
    }
  };


  useEffect(() => {
    getCartData()
  }, [])

  const getCartData = async () => {
    try {
      const cartApiData = await addtocart_get_api();

      // Ensure that cartApiData.Data is an array before setting the state
      if (Array.isArray(cartApiData.Data)) {
        setCartData(cartApiData.Data.length);

        localStorage.setItem("cartItemCount", cartApiData.Data.length)
      } else {
        console.error("API data format is not as expected");
      }
      // Fetch the GST rate
    } catch (error) {
      console.error("Error fetching API data:", error);
    }


  }

  const handleAddToCart = async () => {
    if (quantityproductUnit < product_detail.minimumqty) {
      handleExceptionError("Please add at least " + product_detail.minimumqty + " Quantity");
      return;
    }
    await addtocart_api_call();
    setAddtoCartButton(true)
    setOpenCartModal(true)



  };

  const handleAddToCart2 = async () => {
    if (quantityproductUnit < product_detail.minimumqty) {
      handleExceptionError("Please add at least " + product_detail.minimumqty + " Quantity");
      return;
    }

    var error = 0;
    const updatedProductAttributeData = [...productattributedata];
    updatedProductAttributeData.map((productattribute, index) => {
      if (productattribute.Value == "") {
        updatedProductAttributeData[
          index
        ].Error = `${updatedProductAttributeData[index].Attribute.AttributeName} is required`;
        error = 1;
      }
    });
    setproductattributedata(updatedProductAttributeData);
    if (error === 1) {
      return false;
    }
    await addtocart_api_call();

    setOpenCartModal(true)

  };

  const RequestAQuote = async () => { };
  const openNewTab = (url) => {
    window.open(url, "_blank");
  };

  const [values, setValues] = useState([]);
  const [values2, setValues2] = useState([]);
  const handleInputChange = (e, index, attribute) => {
    const newValues = [...values];
    const newValues2 = [...values2];
    newValues[index] = parseInt(e.target.value) || 0;
    newValues2[index] = {
      name: attribute,
      value: parseInt(e.target.value) || 0,
    };
    setValues(newValues);

    setValues2(newValues2);
    calculateSum(newValues);
  };

  const calculateSum = (numbers) => {
    if (numbers.length === 0) {
      return;
    }
    const total = numbers.reduce((acc, curr) => {
      if (typeof acc === "undefined" || typeof curr === "undefined") {
        return acc;
      }
      return acc + curr;
    }, 0);

    if (typeof total !== "undefined") {
      setQuantityproductUnit(total);
    }
  };

  const handleAddToCart3 = async () => {
    if (quantityproductUnit < product_detail.minimumqty) {
      handleExceptionError("Please add at least " + product_detail.minimumqty + " Quantity");
      return;
    }
    const newValues2 = [...values2];
    var allattribute = newValues2.filter((item) => item !== undefined);

    let adjustedBasePrice = minPriceToShow;
    var AdditionalCost = 0;
    if (adjustedBasePrice < adjustedBasePrice) {
      adjustedBasePrice += 20; // Adding AdditionalCost
      AdditionalCost = 20;
    }

    const cartParams = {
      ProductID: productId,
      ProductVariationID: 0,
      BasePrice: adjustedBasePrice,
      QTY: quantityproductUnit,
      AdditionalCost: 0,
      ProductSetupPrice: productSetupPrice.map((priceItem) => ({
        ProductSetupPriceID: priceItem.ProductSetupPriceID,
        QTY: quantityPerUnit, // You can replace with appropriate logic
      })),
      randomnumber: allattribute,
    };

    // Call the API with the sorting filter
    setAddtoCartButton(false);
    try {
      const res = await addtocart_api(cartParams);
      getCartData()
      handleExceptionSuccessMessages(res);
      setTimeout(() => {
        if (res) {
          // navigate("/cart");
        } else {
        }
      }, 2500);
      getCartData()
      setOpenCartModal(true)
    } catch (res) {
      setAddtoCartButton(true);
      handleExceptionError(res.response.data.ErrorMessage);
      setTimeout(() => {
        if (res.response.data.ErrorMessage) {
          navigate("/signin");
        } else {
        }
      }, 2500);
    }

  };
  const [login, setlogin] = useState(false);
  const handleloginbutton = () => {
    setlogin(true);
  };


  const [zoomLevel, setZoomLevel] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = (e.pageX - left) / width;
    const y = (e.pageY - top) / height;

    setPosition({ x, y });
  };

  const handleMouseEnter = () => {
    setZoomLevel(2); // Set your desired zoom level
  };

  const handleMouseLeave = () => {
    setZoomLevel(1); // Reset zoom level on mouse leave
  };
  return (
    <div>

      <ErrorSnackbar
        errorMessages={ExceptionError}
        onClearErrors={clearErrors}
      />
      <SuccessSnackbar
        successMessages={successMessages}
        onclearSuccess={clearSuccess}
      />
      <Head_CateHead />
      <div className='product_detail_wrapper'>
        <div className='container'>
          <div className='pro_detail_main'>
            <div className='product_detail'>
              <div className='product_img'>
                <div
                  onMouseMove={handleMouseMove}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className={`product_main_img ${activeSlide === -1 ? "active" : ""
                    }`} >
                  {activeSlide === -1 && product_detail.file_name ? (
                    <img
                      style={{
                        transform: `scale(${zoomLevel})`,
                        transformOrigin: `${position.x * 100}% ${position.y * 100}%`,
                      }}
                      src={`${process.env.REACT_APP_API_URL + product_detail.file_name
                        }`}
                      alt={product_detail.productname}
                    />
                  ) : subproduct[activeSlide]?.ProductGallery ? (
                    <img

                      style={{
                        transform: `scale(${zoomLevel})`,
                        transformOrigin: `${position.x * 100}% ${position.y * 100}%`,
                      }}
                      src={
                        process.env.REACT_APP_API_URL +
                        `${subproduct[activeSlide].ProductGallery}`
                      }
                      alt={product_detail.productname}
                    />
                  ) : (
                    <div>No image available</div>
                  )}
                </div>
                <div className='product_option_img'>
                  <div className='product_slider_container'>
                    <div
                      className='product_slider_wrapper'
                      style={{
                        transform: `translateX(-${activeSlide * 11.5}vw)`,
                      }}>
                      {/* Display the main product image */}
                      <div
                        className={`product_option2_img ${activeSlide === -1 ? "active" : ""
                          }`}
                        onClick={() => handleSlideClick(-1)}>
                        <img
                          src={`${process.env.REACT_APP_API_URL +
                            product_detail.file_name
                            }`}
                          alt={product_detail.productname}
                        />
                      </div>

                      {/* Display subproduct images */}
                      {subproduct.map((subproductItem, index) => (
                        <div
                          className={`product_option2_img ${index === activeSlide ? "active" : ""
                            }`}
                          key={index}
                          onClick={() => handleSlideClick(index)}>
                          <img
                            src={
                              process.env.REACT_APP_API_URL +
                              `${subproductItem.ProductGallery}`
                            }
                            alt={product_detail.productname}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <button
                    onClick={() => handlePrevNext("prev")}
                    disabled={activeSlide === -1}
                    className={`prev_btn ${activeSlide === -1 ? "disable-button" : ""
                      }`}>
                    &#10094;
                  </button>
                  <button
                    disabled={subproduct.length == activeSlide + 1}
                    onClick={() => handlePrevNext("next")}
                    className={`next_btn ${subproduct.length == activeSlide + 1
                      ? "disable-button"
                      : ""
                      }`}>
                    &#10095;
                  </button>
                </div>
              </div>

              <div className='product_detail_content'>
                <div className='new_client_menu'>
                  <div className='product_name'>
                    <h2>{product_detail.productname}</h2>
                  </div>
                  <div className='product_size' style={{ marginTop: "15px" }}>
                    <h5>
                      SKU : <span>{product_detail.productSKU}</span>
                    </h5>
                  </div>
                  <ul className='account_menu'>
                    <li
                      className={activeTab === "tab1" ? "active" : ""}
                      onClick={() => handleTabClick("tab1")}>
                      DETAILS
                    </li>
                    <li
                      className={activeTab === "tab4" ? "active" : ""}
                      onClick={() => handleTabClick("tab4")}>
                      STOCK
                    </li>
                    <li
                      className={activeTab === "tab2" ? "active" : ""}
                      onClick={() => handleTabClick("tab2")}>
                      PRICING
                    </li>

                    <li
                      className={activeTab === "tab3" ? "active" : ""}
                      onClick={() => handleTabClick("tab3")}>
                      BRANDING TEMPLATE
                    </li>
                  </ul>
                  <hr className='hr_set' />
                </div>

                {activeTab === "tab1" && (
                  <>
                    <div className='product_disc_rating'>
                      <div className='expert-detail pro_detail'>
                        {/* <div className="expert-color">
                                                    <h4>Blue</h4>
                                                </div>
                                                <hr /> */}
                        <div className='expert-rating'>
                          {product_detail.Rating != "" ? (
                            <img src='/star.svg' />
                          ) : (
                            ""
                          )}
                          <h4>
                            {product_detail.Rating != ""
                              ? product_detail.Rating != ""
                                ? product_detail.Rating /
                                product_detail.NumberRating
                                : "0"
                              : ""}{" "}

                            {product_detail.Rating != ""
                              ? product_detail.NumberRating != ""
                                ? "(" + product_detail.NumberRating
                                : "0"
                              : ""}{" "}
                            {product_detail.Rating != "" ? "reviews)" : ""}
                          </h4>
                        </div>
                      </div>
                    </div>
                    {product_detail.features != "" &&
                      product_detail.features != null && (
                        <div style={{ marginTop: "15px" }}>
                          <h5>
                            <span>Features</span>
                          </h5>
                          <ul
                            style={{
                              listStyle: "outside",
                              marginLeft: "12px",
                              fontFamily: "poppins",
                              fontSize: "0.83vw",
                              color: "#555",
                              fontWeight: "400",
                            }}>
                            {product_detail.features?.map(
                              (product_detail_features, i) => {
                                return (
                                  <li key={i}>{product_detail_features}</li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      )}

                    <div style={{ marginTop: "15px" }}>
                      <h5>
                        <span>Description</span>
                      </h5>
                      <ul
                        style={{
                          fontFamily: "poppins",
                          fontSize: "0.83vw",
                          color: "#555",
                          fontWeight: "400",
                        }}>
                        <li
                          dangerouslySetInnerHTML={{
                            __html: product_detail.productdescription,
                          }}
                        />
                      </ul>
                    </div>
                    {product_detail.additional_specifications != "" &&
                      product_detail.additional_specifications != null && (
                        <div style={{ marginTop: "15px" }}>
                          <h5>
                            <span>Specifications</span>
                          </h5>
                          <ul
                            style={{
                              listStyle: "outside",
                              marginLeft: "12px",
                              fontFamily: "poppins",
                              fontSize: "0.83vw",
                              color: "#555",
                              fontWeight: "400",
                            }}>
                            {product_detail.additional_specifications?.map(
                              (additional_specifications, i) => {
                                return (
                                  <li key={i}>
                                    <b>
                                      {additional_specifications.specification}
                                    </b>
                                    : {additional_specifications.description}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      )}
                    {product_detail.dimensions != "" &&
                      product_detail.dimensions != null && (
                        <div style={{ marginTop: "15px" }}>
                          <h5>
                            <span>Dimensions</span>
                          </h5>
                          <ul
                            style={{
                              listStyle: "outside",
                              marginLeft: "12px",
                              fontFamily: "poppins",
                              fontSize: "0.83vw",
                              color: "#555",
                              fontWeight: "400",
                            }}>
                            {product_detail.dimensions?.map(
                              (product_detail_dimensions, i) => {
                                return (
                                  <li key={i}>{product_detail_dimensions}</li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      )}
                    {product_detail.additional_materials != "" &&
                      product_detail.additional_materials != null && (
                        <div style={{ marginTop: "15px" }}>
                          <h5>
                            <span>Materials</span>
                          </h5>
                          <ul
                            style={{
                              listStyle: "outside",
                              marginLeft: "12px",
                              fontFamily: "poppins",
                              fontSize: "0.83vw",
                              color: "#555",
                              fontWeight: "400",
                            }}>
                            {product_detail.additional_materials?.map(
                              (additional_materials, i) => {
                                return (
                                  <li key={i}>
                                    <b>{additional_materials.component}</b>:{" "}
                                    {additional_materials.material}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      )}
                    {product_detail.branding_options != "" &&
                      product_detail.branding_options != null && (
                        <>
                          {product_detail.branding_options?.map(
                            (branding_option, i) => {
                              const print_descriptions =
                                branding_option.print_description.split("|");
                              return (
                                <div style={{ marginTop: "15px" }}>
                                  <h5>
                                    <span>{branding_option.print_type}</span>
                                  </h5>
                                  <ul
                                    style={{
                                      listStyle: "outside",
                                      marginLeft: "12px",
                                      fontFamily: "poppins",
                                      fontSize: "0.83vw",
                                      color: "#555",
                                      fontWeight: "400",
                                    }}>
                                    {print_descriptions?.map(
                                      (print_description, i) => {
                                        return <li>{print_description}</li>;
                                      }
                                    )}
                                  </ul>
                                </div>
                              );
                            }
                          )}
                        </>
                      )}

                    {product_detail.carton != "" &&
                      product_detail.carton != null && (
                        <div style={{ marginTop: "15px" }}>
                          <ul
                            style={{
                              fontFamily: "poppins",
                              fontSize: "0.83vw",
                              color: "#555",
                              fontWeight: "400",
                            }}>
                            {product_detail.carton.length &&
                              product_detail.carton.width &&
                              product_detail.carton.height && (
                                <li>
                                  <b>Carton Dimensions:</b>:{" "}
                                  {product_detail.carton.length}cm x{" "}
                                  {product_detail.carton.width}cm x{" "}
                                  {product_detail.carton.height}cm
                                </li>
                              )}
                            {product_detail.carton.quantity && (
                              <li>
                                <b>Carton Quantity</b>:{" "}
                                {product_detail.carton.quantity}pieces
                              </li>
                            )}
                            {product_detail.carton.weight && (
                              <li>
                                <b>Carton Weight</b>:{" "}
                                {product_detail.carton.weight}kg
                              </li>
                            )}
                          </ul>
                        </div>
                      )}

                    <div className='product_size'>
                      <h5>
                        Availability :{" "}
                        <span>{product_detail.productstock}</span>
                      </h5>
                    </div>

                    {product_detail.getinstantcourt == 1 ? (
                      <>
                        <hr />
                        <div className='product_quote_n_requested_title new_client_menus'>
                          <ul className='account_menus'>
                            <li
                              className={
                                activeTabing === "tab11" ? "active" : ""
                              }
                              onClick={() => handleTabClicking("tab11")}>
                              <h2>Get an instant quote</h2>
                            </li>
                          </ul>
                        </div>
                        <hr />
                        <div className='product_content'>
                          {activeTabing === "tab11" && (
                            <div className='product_quote_n_requested_content'>
                              <div className='product_quote'>
                                <div className='product_quote_base'>
                                  <h1 className='mb-1 f-14 fw-500'>
                                    How many {product_detail.productname} would
                                    you like?
                                  </h1>
                                  <div className='d-flex gap-2 mb-1'>
                                    {productUnbrandedPrices?.map((item, i) => {
                                      return (
                                        <a
                                          key={i}
                                          value={item.QTY}
                                          className={`color-size-qty-btn ${quantityproductUnit === item.QTY
                                            ? "active-color-size-qty-btn"
                                            : ""
                                            }`}
                                          onClick={() => {
                                            setQuantityproductUnit(item.QTY);
                                          }}>
                                          {item.QTY}
                                        </a>
                                      );
                                    })}
                                  </div>
                                  <div className='d-flex gap-2 align-items-center mb-1'>
                                    <h3 className='mb-0 f-14 fw-500'>Other</h3>
                                    <input
                                      type='number'
                                      value={quantityproductUnit}
                                      className='color-size-qty-btn f-16'
                                      onChange={(e) => {
                                        setQuantityproductUnit(e.target.value);
                                        if (selectedPrint != "") {
                                          setQuantityPerUnit(e.target.value);
                                        }
                                      }}
                                    />
                                  </div>
                                </div>

                                {isProductTypeVisible && (
                                  <div>
                                    {productattributedata.map(
                                      (PAObj, index) => {
                                        // Create a Set to store unique AttributeValueIDs
                                        const uniqueAttributeValues = new Set();
                                        return (
                                          <div
                                            className='product_detail_input'
                                            key={PAObj.Attribute.AttributeID}>
                                            <p className='f-14 fw-500 mb-1'>
                                              What{" "}
                                              {PAObj.Attribute.AttributeName}{" "}
                                              {product_detail.productname} would
                                              you like?
                                            </p>
                                            <div className='d-flex gap-2 mb-1'>
                                              {productVariationAttributeValues.map(
                                                (PVAVObj, index2) => {
                                                  // Check if the AttributeValueID is unique
                                                  if (
                                                    PVAVObj.AttributeValue
                                                      .AttributeID ===
                                                    PAObj.Attribute
                                                      .AttributeID &&
                                                    !uniqueAttributeValues.has(
                                                      PVAVObj.AttributeValue
                                                        .AttributeValueID
                                                    )
                                                  ) {
                                                    // Add the AttributeValueID to the Set to mark it as used
                                                    uniqueAttributeValues.add(
                                                      PVAVObj.AttributeValue
                                                        .AttributeValueID
                                                    );

                                                    return (
                                                      <a
                                                        key={index2}
                                                        id={
                                                          PVAVObj.AttributeValue
                                                            .AttributeValueID
                                                        }
                                                        className={`color-size-qty-btn ${productattributedata[
                                                          index
                                                        ].Value ==
                                                          PVAVObj.AttributeValue
                                                            .AttributeValueID
                                                          ? "active-color-size-qty-btn"
                                                          : ""
                                                          }`}
                                                        onClick={(event) =>
                                                          handleAttributeChange(
                                                            event,
                                                            index
                                                          )
                                                        }>
                                                        {
                                                          PVAVObj.AttributeValue
                                                            .AttributeValue
                                                        }
                                                      </a>
                                                    );
                                                  }
                                                  return null;
                                                }
                                              )}
                                            </div>
                                            {PAObj.Error && (
                                              <p className='error'>
                                                {PAObj.Error}
                                              </p>
                                            )}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                )}

                                <div className='product_quote4 shipping'>
                                  {productSetupPrice.length > 0 ? (
                                    <h3>
                                      How do you want your{" "}
                                      {product_detail.productname} branded?
                                    </h3>
                                  ) : (
                                    <></>
                                  )}
                                  <div className='product_shipping_types'>
                                    {productSetupPrice.map((priceItem) => (
                                      <div
                                        className={`color-size-qty-btn ${selectedPrint ===
                                          priceItem.ProductSetupPriceID
                                          ? "active-color-size-qty-btn"
                                          : ""
                                          }`}
                                        onClick={() =>
                                          handlePrintSelection(
                                            priceItem.ProductSetupPriceID
                                          )
                                        }>
                                        {priceItem.SetupName}
                                      </div>
                                    ))}
                                  </div>
                                </div>

                                {product_detail.howmanypositions != "" &&
                                  product_detail.howmanypositions != null && (
                                    <>
                                      <div className='product_quote4 shipping'>
                                        <h3>
                                          How many positions do you want branded
                                          on your {product_detail.productname}?
                                        </h3>
                                        <div className='product_shipping_types'>
                                          {product_detail.howmanypositions?.map(
                                            (howmanypositions, i) => {
                                              return (
                                                <div
                                                  className={`color-size-qty-btn ${selectedSidePosition ===
                                                    howmanypositions
                                                    ? "active-color-size-qty-btn"
                                                    : ""
                                                    }`}
                                                  onClick={() =>
                                                    handlesidePosition(
                                                      howmanypositions
                                                    )
                                                  }>
                                                  {howmanypositions}
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>

                                      <div className='trend_bottom_btn pro_detail'>
                                        <button
                                          onClick={() =>
                                            handleCalculatePrice(1)
                                          }>
                                          Calculate my price
                                        </button>
                                      </div>
                                    </>
                                  )}

                                {selectedPrice && (
                                  <div className=' d-flex flex-column  align-items-center justify-content-center'>
                                    <p className='text-blue'>Item</p>
                                    <p>
                                      {product_detail.productSKU} /{" "}
                                      {product_detail.productname}
                                    </p>
                                    <p className='text-blue'>Unit Total</p>
                                    <p>({quantityproductUnit})</p>
                                    {productattributedata?.map((atr, i) => (
                                      <div className='d-flex flex-column  align-items-center justify-content-center'>
                                        <p className='text-blue'>
                                          tem {atr.Attribute.AttributeName}
                                        </p>
                                        <p>
                                          (
                                          {
                                            productVariationAttributeValues.filter(
                                              (item) => {
                                                return (
                                                  item.AttributeValue
                                                    .AttributeValueID ==
                                                  atr.Value
                                                );
                                              }
                                            )[0]?.AttributeValue.AttributeValue
                                          }
                                          )
                                        </p>
                                      </div>
                                    ))}
                                    <p className='text-blue'>Branding Option</p>
                                    <p>
                                      (
                                      {selectedPrint ===
                                        "Full Colour Digital Print"
                                        ? selectedPrint.slice(12)
                                        : selectedPrint}{" "}
                                      {selectedSidePosition})
                                    </p>
                                    <p className='text-blue'>
                                      Unit Cost $
                                      {typeof minPriceToShow === "number"
                                        ? minPriceToShow.toFixed(2)
                                        : minPriceToShow}{" "}
                                      - Total Price (ex gst)
                                    </p>
                                    <p className='text-blue'>
                                      TOTAL INC GST ${totalCost.toFixed(2)}
                                    </p>
                                    <div className='trend_bottom_btn pro_detail'>
                                      {isProductTypeVisible &&
                                        selectedPrice &&
                                        AddtoCartButton ? (
                                        <button onClick={handleAddToCart2}>
                                          ADD TO CART
                                        </button>
                                      ) : (
                                        AddtoCartButton && (
                                          <button onClick={handleAddToCart}>
                                            ADD TO CART
                                          </button>
                                        )
                                      )}
                                    </div>
                                  </div>
                                )}

                                {product_detail.SKUPrefix != "" &&
                                  product_detail.SKUPrefix != null && (
                                    <>
                                      <div className='pro_line'>
                                        <hr />
                                        <span>or</span>
                                        <hr />
                                      </div>
                                      <div className='pro_detail_bott_btm'>
                                        <Button
                                          onClick={() =>
                                            handleCalculatePrice2(2)
                                          }
                                          variant='contained'
                                          color='primary'
                                          sx={{
                                            backgroundColor: "white",
                                            borderRadius: "40px",
                                            border: "1px solid #01AEF0",
                                            boxShadow:
                                              "0px 0px 8px 3px rgba(0, 0, 0, 0.05)",
                                            color: "#01AEF0",
                                            fontFamily: "Poppins",
                                            fontSize: "0.83vw",
                                            fontWeight: 500,
                                            width: "13.54vw",
                                            height: "2.60vw",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}>
                                          Request a quote
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            handleCalculatePrice2(3)
                                          }
                                          variant='contained'
                                          color='primary'
                                          sx={{
                                            backgroundColor: "white",
                                            borderRadius: "40px",
                                            border: "1px solid #01AEF0",
                                            boxShadow:
                                              "0px 0px 8px 3px rgba(0, 0, 0, 0.05)",
                                            color: "#01AEF0",
                                            fontFamily: "Poppins",
                                            fontSize: "0.83vw",
                                            fontWeight: 500,
                                            width: "13.54vw",
                                            height: "2.60vw",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}>
                                          Request a sample
                                        </Button>
                                      </div>
                                    </>
                                  )}
                              </div>
                              <div className='product_requested'></div>
                            </div>
                          )}
                          {activeTabing === "tab12" && (
                            <div className='tab-content'>
                              <p>No any content added yet!</p>
                            </div>
                          )}
                        </div>
                        <hr className='hr_pro' />

                        <div className='bottom_check_detail'>
                          <div className='national_shipping'>
                            <img src={earth} />
                            <h4>Nationwide Delivery</h4>
                            <h5>Fast delivery nationwide.</h5>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='product_content'>
                          {activeTabing === "tab11" && (
                            <>
                              {product_detail.SKUPrefix != "" &&
                                product_detail.SKUPrefix != null && (
                                  <>
                                    <hr />
                                    <div className='product_quote_n_requested_content'>
                                      <div className='product_quote'>
                                        <div className='pro_detail_bott_btm'>
                                          <Button
                                            onClick={() =>
                                              handleCalculatePrice2(2)
                                            }
                                            variant='contained'
                                            color='primary'
                                            sx={{
                                              backgroundColor: "white",
                                              borderRadius: "40px",
                                              border: "1px solid #01AEF0",
                                              boxShadow:
                                                "0px 0px 8px 3px rgba(0, 0, 0, 0.05)",
                                              color: "#01AEF0",
                                              fontFamily: "Poppins",
                                              fontSize: "0.83vw",
                                              fontWeight: 500,
                                              width: "13.54vw",
                                              height: "2.60vw",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}>
                                            Request a quote
                                          </Button>
                                          <Button
                                            onClick={() =>
                                              handleCalculatePrice2(3)
                                            }
                                            variant='contained'
                                            color='primary'
                                            sx={{
                                              backgroundColor: "white",
                                              borderRadius: "40px",
                                              border: "1px solid #01AEF0",
                                              boxShadow:
                                                "0px 0px 8px 3px rgba(0, 0, 0, 0.05)",
                                              color: "#01AEF0",
                                              fontFamily: "Poppins",
                                              fontSize: "0.83vw",
                                              fontWeight: 500,
                                              width: "13.54vw",
                                              height: "2.60vw",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}>
                                            Request a sample
                                          </Button>
                                        </div>
                                      </div>
                                      <div className='product_requested'></div>
                                    </div>
                                  </>
                                )}
                            </>
                          )}
                          {activeTabing === "tab12" && (
                            <div className='tab-content'>
                              <p>No any content added yet!</p>
                            </div>
                          )}
                        </div>
                        <hr className='hr_pro' />

                        <div className='bottom_check_detail'>
                          <div className='national_shipping'>
                            <img src={earth} />
                            <h4>Nationwide Delivery</h4>
                            <h5>Fast delivery nationwide.</h5>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}

                {activeTab === "tab2" && localStorage.getItem("token") && (
                  <>
                    {product_detail.unbrandedpriceshow === 1 && (

                      <table className='table w-100 styled-table'>
                        <thead>
                          <tr>
                            <th
                              colSpan={productUnbrandedPrices?.length + 1}
                              className='thead'>
                              {product_detail.FlagName != ""
                                ? product_detail.FlagName
                                : "UNBRANDED PRICE"}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>QTY</td>
                            {productUnbrandedPrices.map((priceItem) => (
                              <td key={priceItem.ProductUnbrandedPriceID}>
                                {priceItem.QTY}
                              </td>
                            ))}
                          </tr>
                          <tr>
                            <td>Price</td>
                            {productUnbrandedPrices.map(
                              (priceItem, priceIndex) => {
                                var markupValue = priceItem.Retailer;
                                if (storedRoleID == 0) {
                                  markupValue = priceItem.Admin;
                                }
                                if (storedRoleID == 2) {
                                  markupValue = priceItem.Wholesaler;
                                }
                                if (storedRoleID == 3) {
                                  markupValue = priceItem.PortalUser;
                                }
                                if (storedRoleID == 4) {
                                  markupValue = priceItem.VIPUser;
                                }

                                const finalResult = (
                                  parseFloat(priceItem.Price) +
                                  (parseFloat(priceItem.Price) *
                                    parseFloat(markupValue)) /
                                  100
                                ).toFixed(2);

                                return (
                                  <td key={priceItem.ProductUnbrandedPriceID}>
                                    <div key={priceIndex}>${finalResult}</div>
                                  </td>
                                );
                              }
                            )}
                          </tr>

                          {(storedRoleID == 0 || storedRoleID == 2) && (
                            <tr>
                              <td>Retails</td>
                              {productUnbrandedPrices.map((priceItem, priceIndex) => {
                                var markupValue2 = priceItem.Retailer;

                                const finalResult = (
                                  parseFloat(priceItem.Price) +
                                  (parseFloat(priceItem.Price) * parseFloat(markupValue2)) / 100
                                ).toFixed(2);

                                return (
                                  <td key={priceItem.ProductUnbrandedPriceID}>
                                    <div key={priceIndex}>${finalResult}</div>
                                  </td>
                                );
                              })}
                            </tr>
                          )}


                        </tbody>
                      </table>
                    )}
                    {product_detail.unbrandedpriceshow === 0 && (

                      <table className='table w-100 styled-table'>
                        <thead>
                          <tr>
                            <th
                              colSpan={productUnbrandedPrices?.length + 1}
                              className='thead'>
                              UNIT PRICE
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {productUnbrandedPrices.length > 1 && (
                            <tr>
                              <td>Quantity</td>
                              {productUnbrandedPrices.map((priceItem) => (
                                <td key={priceItem.ProductUnbrandedPriceID}>
                                  {priceItem.QTY}
                                </td>
                              ))}
                            </tr>
                          )}
                          <tr>
                            <td>Price</td>
                            {calculatedPricesArray?.length > 0
                              ? calculatedPricesArray.map(
                                (calculatedPricing, index) => (
                                  <td key={index}>${calculatedPricing}</td>
                                )
                              )
                              : productUnbrandedPrices?.map((unbrand) => {
                                let markup = unbrand.Retailer;
                                if (storedRoleID == 0) {
                                  markup = unbrand.Admin;
                                }
                                if (storedRoleID == 2) {
                                  markup = unbrand.Wholesaler;
                                }
                                if (storedRoleID == 3) {
                                  markup = unbrand.PortalUser;
                                }
                                if (storedRoleID == 4) {
                                  markup = unbrand.VIPUser;
                                }
                                const perUnit =
                                  Number(unbrand.Price) +
                                  (Number(unbrand.Price) * Number(markup)) /
                                  100;
                                return <td>{perUnit?.toFixed(2)}</td>;
                              })}
                          </tr>
                        </tbody>
                      </table>
                    )}

                    {productSetupPrice?.length > 0 && product_detail.setupchargeshow === 1 && (
                      <table className='table w-100 styled-table'>
                        <thead>
                          <tr>
                            <th colSpan={5} className='thead'>
                              {product_detail.CostFlag != ""
                                ? product_detail.CostFlag
                                : "ADDITIONAL COSTS"}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            {/* <td>Per Unit</td> */}
                            {/* <td>Setup Charge</td> */}
                            <td>Quantity Per Unit</td>
                          </tr>

                          {productSetupPrice.map((priceItem, index) => (
                            <tr key={priceItem.ProductSetupPriceID}>
                              <td>{priceItem.SetupName}</td>
                              {/* <td>${priceItem.UnitPrice}</td> */}
                              {/* <td>${priceItem.SetupPrice}</td> */}
                              <td className='product_detail_input additional_costs'>
                                <input
                                  type='number'
                                  value={
                                    priceItem.Default === true
                                      ? 1
                                      : priceItem.Default === false
                                        ? 0
                                        : priceItem.Default
                                  }
                                  onChange={(e) => {
                                    const newValue = parseInt(
                                      e.target.value,
                                      10
                                    ); // Convert input value to an integer
                                    calculatePrices2(newValue, index, 1);
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}


                    {product_detail.unbrandedpriceshow == 1 && (
                      <table className='table w-100 styled-table'>
                        <thead>
                          <tr>
                            <th
                              colSpan={productUnbrandedPrices?.length + 1}
                              className='thead'>
                              PRICE SUMMARY
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Quantity</td>
                            {productUnbrandedPrices.map((priceItem) => (
                              <td key={priceItem.ProductUnbrandedPriceID}>
                                {priceItem.QTY}
                              </td>
                            ))}
                          </tr>

                          <tr>
                            <td>Buy (unit) inc setups</td>
                            {calculatedPricesArray?.length > 0
                              ? calculatedPricesArray.map(
                                (calculatedPricing, index) => (
                                  <td key={index}>${calculatedPricing}</td>
                                )
                              )
                              : productUnbrandedPrices?.map((unbrand) => {
                                let markup = unbrand.Retailer;
                                if (storedRoleID == 0) {
                                  markup = unbrand.Admin;
                                }
                                if (storedRoleID == 2) {
                                  markup = unbrand.Wholesaler;
                                }
                                if (storedRoleID == 3) {
                                  markup = unbrand.PortalUser;
                                }
                                if (storedRoleID == 4) {
                                  markup = unbrand.VIPUser;
                                }
                                const perUnit =
                                  Number(unbrand.Price) +
                                  (Number(unbrand.Price) * Number(markup)) /
                                  100;
                                return <td>{perUnit?.toFixed(2)}</td>;
                              })}
                          </tr>
                          <tr>
                            <td>Buy (total) inc setups</td>
                            {productUnbrandedPrices.map(
                              (priceItem, priceIndex) => {
                                var markupValue = priceItem.Retailer;
                                if (storedRoleID == 0) {
                                  markupValue = priceItem.Admin;
                                }
                                if (storedRoleID == 2) {
                                  markupValue = priceItem.Wholesaler;
                                }
                                if (storedRoleID == 3) {
                                  markupValue = priceItem.PortalUser;
                                }
                                if (storedRoleID == 4) {
                                  markupValue = priceItem.VIPUser;
                                }
                                const finalResult = (
                                  parseFloat(priceItem.Price) +
                                  (parseFloat(priceItem.Price) *
                                    parseFloat(markupValue)) /
                                  100
                                ).toFixed(2);
                                const totalPrice =
                                  parseFloat(priceItem.QTY) *
                                  calculateBuyUnitIncSetups2(
                                    priceItem,
                                    productSetupPrice,
                                    markupValue,
                                    finalResult
                                  );
                                return (
                                  <td key={priceItem.ProductUnbrandedPriceID}>
                                    ${totalPrice.toFixed(2)}
                                  </td>
                                );
                              }
                            )}
                          </tr>
                        </tbody>
                      </table>
                    )}

                    {(storedRoleID == 0 || storedRoleID == 2) && product_detail.unbrandedpriceshow == 1 && (
                      <table className='table w-100 styled-table'>
                        <thead>
                          <tr>
                            <th
                              colSpan={productUnbrandedPrices?.length + 1}
                              className='thead'>
                              PRICE SUMMARY - RETAIL
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Quantity</td>
                            {productUnbrandedPrices.map((priceItem) => (
                              <td key={priceItem.ProductUnbrandedPriceID}>
                                {priceItem.QTY}
                              </td>
                            ))}
                          </tr>

                          <tr>
                            <td>Buy (unit) inc setups</td>
                            {calculatedPricesArray2?.length > 0
                              ? calculatedPricesArray2.map(
                                (calculatedPricing, index) => (
                                  <td key={index}>${calculatedPricing}</td>
                                )
                              )
                              : productUnbrandedPrices?.map((unbrand) => {
                                let markup = unbrand.Retailer;
                                const perUnit =
                                  Number(unbrand.Price) +
                                  (Number(unbrand.Price) * Number(markup)) /
                                  100;
                                return <td>{perUnit?.toFixed(2)}</td>;
                              })}
                          </tr>
                          <tr>
                            <td>Buy (total) inc setups</td>
                            {productUnbrandedPrices.map(
                              (priceItem, priceIndex) => {
                                var markupValue = priceItem.Retailer;
                                const finalResult = (
                                  parseFloat(priceItem.Price) +
                                  (parseFloat(priceItem.Price) *
                                    parseFloat(markupValue)) /
                                  100
                                ).toFixed(2);
                                const totalPrice =
                                  parseFloat(priceItem.QTY) *
                                  calculateBuyUnitIncSetups2(
                                    priceItem,
                                    productSetupPrice,
                                    markupValue,
                                    finalResult
                                  );
                                return (
                                  <td key={priceItem.ProductUnbrandedPriceID}>
                                    ${totalPrice.toFixed(2)}
                                  </td>
                                );
                              }
                            )}
                          </tr>
                        </tbody>
                      </table>
                    )}
                    {product_detail.unbrandedpriceshow === 1 && (
                      <table className='table w-100 styled-table'>
                        <thead>
                          <tr>
                            <th colSpan={5} className='thead'>
                              {product_detail.InformationFlag != ""
                                ? product_detail.InformationFlag
                                : "INFORMATION"}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className='text-justify'>
                              {product_detail.InformationContain == "" ? (
                                <>
                                  <p>
                                    Less than minimum quantities are available for
                                    a $20 surcharge on ex-stock orders.
                                  </p>
                                  <p> Prices are in AUD and exclude GST.</p>
                                  <p>
                                    Freight is free of charge to one location in
                                    Australia or New Zealand.
                                  </p>
                                  <p>
                                    Split delivery is available at $20 per
                                    additional delivery location.
                                  </p>
                                </>
                              ) : (
                                <>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: product_detail.InformationContain,
                                    }}
                                  />
                                </>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}

                    <div className='product_quote_n_requested_content '>
                      <div className='product_quote'>
                        {product_detail.isordergrid != 1 && (
                          <div className='product_quote1'>
                            <div className='product_quote_base'>
                              <div className='d-flex gap-2 align-items-center '>
                                <h3 className='mb-0'>Quantity</h3>
                                <input
                                  type='number'
                                  value={quantityproductUnit}
                                  className='color-size-qty-btn f-16'
                                  onChange={(e) => {
                                    setQuantityproductUnit(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {isProductTypeVisible &&
                          product_detail.isordergrid == 1 && (
                            <table class='table w-100 styled-table'>
                              <thead>
                                <tr>
                                  {product_detail.ProductAttribute.length ==
                                    1 ? (
                                    <th colspan='2' class='thead'>
                                      ORDER GRID
                                    </th>
                                  ) : (
                                    <>
                                      <th colspan={product_detail.ProductAttributeValue.length} class='thead'>
                                        ORDER GRID
                                      </th>
                                    </>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {product_detail.ProductAttribute.length == 1 ? (
                                  <>
                                    <tr>
                                      <th style={{ textAlign: "left", padding: '4px' }}>
                                        {
                                          product_detail.ProductAttribute[0]
                                            .Attribute.AttributeName
                                        }
                                      </th>
                                      <th style={{ textAlign: "left", padding: '4px' }}>QTY</th>
                                    </tr>
                                    {product_detail.ProductAttributeValue.map(
                                      (PAObj, index) => {
                                        return (
                                          <tr>
                                            <td style={{ textAlign: "left", padding: '4px' }}>
                                              {
                                                PAObj.AttributeValue
                                                  .AttributeValue
                                              }
                                            </td>
                                            <td style={{ textAlign: "left", padding: '4px' }}>
                                              <input
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    e,
                                                    index,
                                                    PAObj.AttributeValue
                                                      .AttributeValue
                                                  )
                                                }
                                                style={{
                                                  height: "35px",
                                                  borderRadius: "5px",
                                                  width: "40px",
                                                  textAlign: "center",
                                                }}
                                                type=''
                                              />
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <tr>
                                      <th style={{ textAlign: "left", padding: '4px' }}>
                                        {
                                          product_detail.ProductAttribute[0]
                                            .Attribute.AttributeName
                                        }
                                      </th>
                                      {product_detail.ProductAttributeValue.map(
                                        (PAObj, index) => {
                                          return (
                                            <>
                                              {product_detail
                                                .ProductAttribute[1].Attribute
                                                .AttributeID ==
                                                PAObj.AttributeValue
                                                  .AttributeID && (
                                                  <td
                                                    style={{ textAlign: "center", padding: '4px' }}>
                                                    {
                                                      PAObj.AttributeValue
                                                        .AttributeValue
                                                    }
                                                  </td >
                                                )}
                                            </>
                                          );
                                        }
                                      )}
                                    </tr>
                                    {product_detail.ProductAttributeValue.map(
                                      (PAObj, index) => {
                                        return (
                                          <>
                                            {product_detail.ProductAttribute[0]
                                              .Attribute.AttributeID ==
                                              PAObj.AttributeValue
                                                .AttributeID && (
                                                <tr>
                                                  <td
                                                    style={{ textAlign: "left", padding: '4px' }}>
                                                    {
                                                      PAObj.AttributeValue
                                                        .AttributeValue
                                                    }
                                                  </td>
                                                  {product_detail.ProductAttributeValue.map(
                                                    (PAObj2, index2) => {
                                                      var indexnumber = 0;
                                                      return (
                                                        <>
                                                          {product_detail
                                                            .ProductAttribute[1]
                                                            .Attribute
                                                            .AttributeID ==
                                                            PAObj2.AttributeValue
                                                              .AttributeID && (
                                                              <>
                                                                <td
                                                                  style={{
                                                                    textAlign:
                                                                      "left", padding: '4px'
                                                                  }}>
                                                                  <input
                                                                    onChange={(e) =>
                                                                      handleInputChange(
                                                                        e,
                                                                        index *
                                                                        100 +
                                                                        index2,
                                                                        PAObj
                                                                          .AttributeValue
                                                                          .AttributeValue +
                                                                        " " +
                                                                        PAObj2
                                                                          .AttributeValue
                                                                          .AttributeValue
                                                                      )
                                                                    }
                                                                    style={{
                                                                      height:
                                                                        "35px",
                                                                      borderRadius:
                                                                        "5px",
                                                                      width: "40px",
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                    type=''
                                                                  />
                                                                </td>
                                                              </>
                                                            )}
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </tr>
                                              )}
                                          </>
                                        );
                                      }
                                    )}
                                  </>
                                )}
                              </tbody>
                            </table>
                          )}
                        {isProductTypeVisible &&
                          product_detail.isordergrid != 1 && (
                            <div className=''>
                              {productattributedata.map((PAObj, index) => {
                                // Create a Set to store unique AttributeValueIDs
                                const uniqueAttributeValues = new Set();
                                return (
                                  <div
                                    className=' product_detail_input'
                                    key={PAObj.Attribute.AttributeID}>
                                    <p className='f-14 fw-500 mb-1'>
                                      {PAObj.Attribute.AttributeName}
                                    </p>
                                    <div
                                      className='d-flex gap-2 mb-1'
                                    // value={PAObj.Value}
                                    >
                                      {/* <option value="">Select {PAObj.Attribute.AttributeName}</option> */}
                                      {productVariationAttributeValues.map(
                                        (PVAVObj, index2) => {
                                          // Check if the AttributeValueID is unique
                                          if (
                                            PVAVObj.AttributeValue
                                              .AttributeID ===
                                            PAObj.Attribute.AttributeID &&
                                            !uniqueAttributeValues.has(
                                              PVAVObj.AttributeValue
                                                .AttributeValueID
                                            )
                                          ) {
                                            // Add the AttributeValueID to the Set to mark it as used
                                            uniqueAttributeValues.add(
                                              PVAVObj.AttributeValue
                                                .AttributeValueID
                                            );

                                            return (
                                              <a
                                                key={index2}
                                                id={
                                                  PVAVObj.AttributeValue
                                                    .AttributeValueID
                                                }
                                                className={`color-size-qty-btn ${productattributedata[index]
                                                  .Value ==
                                                  PVAVObj.AttributeValue
                                                    .AttributeValueID
                                                  ? "active-color-size-qty-btn"
                                                  : ""
                                                  }`}
                                                onClick={(event) =>
                                                  handleAttributeChange(
                                                    event,
                                                    index
                                                  )
                                                }>
                                                {
                                                  PVAVObj.AttributeValue
                                                    .AttributeValue
                                                }
                                              </a>
                                            );
                                          }
                                          return null;
                                        }
                                      )}
                                    </div>
                                    {PAObj.Error && (
                                      <p className='error'>{PAObj.Error}</p>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                      </div>
                    </div>

                    {product_detail.unbrandedpriceshow === 0 && (
                      <table className='table w-100 styled-table'>
                        <thead>
                          <tr>
                            <th colSpan={5} className='thead'>
                              {product_detail.InformationFlag != ""
                                ? product_detail.InformationFlag
                                : "INFORMATION"}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className='text-justify'>
                              {product_detail.InformationContain == "" ? (
                                <>
                                  <p>
                                    Less than minimum quantities are available for
                                    a $20 surcharge on ex-stock orders.
                                  </p>
                                  <p> Prices are in AUD and exclude GST.</p>
                                  <p>
                                    Freight is free of charge to one location in
                                    Australia or New Zealand.
                                  </p>
                                  <p>
                                    Split delivery is available at $20 per
                                    additional delivery location.
                                  </p>
                                </>
                              ) : (
                                <>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: product_detail.InformationContain,
                                    }}
                                  />
                                </>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}

                    <table className='table w-100 styled-table'>
                      <thead>
                        <tr>
                          <th colSpan={6} className='thead'>
                            TOTAL COST
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Price Per Quantity</td>
                          <td>
                            $
                            {typeof minPriceToShow === "number"
                              ? minPriceToShow.toFixed(2)
                              : minPriceToShow}
                          </td>
                        </tr>
                        <tr>
                          <td>Quantity</td>
                          <td>
                            {typeof quantityproductUnit === "number"
                              ? quantityproductUnit.toFixed(0)
                              : quantityproductUnit}
                          </td>
                        </tr>
                        <tr>
                          <td>Total Amount</td>
                          <td>${totalCost.toFixed(2)}</td>
                        </tr>
                      </tbody>
                    </table>

                    <div className='trend_bottom_btn pro_detail'>
                      {isProductTypeVisible && AddtoCartButton ? (
                        <>
                          {product_detail.isordergrid === 1 ? (
                            <>
                              {quantityproductUnit > 0 && (
                                <button onClick={handleAddToCart3}>
                                  ADD TO CART
                                </button>
                              )}
                            </>
                          ) : (
                            <button onClick={handleAddToCart2}>
                              ADD TO CART
                            </button>
                          )}
                        </>
                      ) : AddtoCartButton ? (
                        <button onClick={handleAddToCart}>ADD TO CART</button>
                      ) : (
                        <button>ADD TO CART</button>
                      )}
                    </div>
                  </>
                )}
                {activeTab === "tab2" && !localStorage.getItem("token") && (
                  <>
                    <button
                      className='login_button'
                      onClick={handleloginbutton}>
                      Login
                    </button>
                    {login && <LoginPopup setLogin={setlogin} />}
                  </>
                )}

                {activeTab === "tab3" && (
                  <div>
                    {product_detail.brandingtemplate === null ? (
                      <>
                        <p>No any branding template added yet!</p>
                      </>
                    ) : (
                      <div>
                        <img
                          style={{ width: "70px", cursor: "pointer" }}
                          src={pdf}
                          onClick={() =>
                            openNewTab(
                              process.env.REACT_APP_API_URL +
                              product_detail.brandingtemplate
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
                {activeTab === "tab4" && (
                  <>
                    <table
                      style={{
                        width: "100%",
                        border: "1px solid rgba(0,0,0,0.1)",
                      }}>
                      <thead style={{ background: "rgba(3,128,176,0.05)" }}>
                        <tr>
                          <th
                            style={{
                              fontWeight: "400",
                              textAlign: "left",
                              padding: "5px 10px",
                            }}>
                            Item
                          </th>
                          <th
                            style={{
                              fontWeight: "400",
                              textAlign: "left",
                              padding: "5px 10px",
                            }}>
                            In Stock
                          </th>
                          <th
                            style={{
                              fontWeight: "400",
                              textAlign: "left",
                              padding: "5px 10px",
                            }}>
                            Next Shipment
                          </th>
                        </tr>
                      </thead>
                      {product_detail.stockDescription != "" &&
                        product_detail.stockDescription != null && (
                          <tbody>
                            {product_detail.stockDescription?.map(
                              (stockDescription, i) => {
                                return (
                                  <tr>
                                    <td
                                      style={{
                                        fontWeight: "400",
                                        textAlign: "left",
                                        padding: "5px 10px",
                                        color: "rgb(107, 114, 128)",
                                      }}>
                                      {stockDescription.description}
                                    </td>
                                    <td
                                      style={{
                                        fontWeight: "400",
                                        textAlign: "left",
                                        padding: "5px 10px",
                                        color: "rgb(107, 114, 128)",
                                      }}>
                                      {stockDescription.quantity}
                                    </td>
                                    <td
                                      style={{
                                        fontWeight: "400",
                                        textAlign: "left",
                                        padding: "5px 10px",
                                        color: "rgb(107, 114, 128)",
                                      }}>
                                      {stockDescription.next_shipment == 0
                                        ? ""
                                        : stockDescription.next_shipment}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        )}
                    </table>
                  </>
                )}
              </div>
            </div>
            {/* <hr style={{ marginTop: "80px" }} /> */}
          </div>
        </div>
      </div>
      {
        showQouteOption == 2 && (
          <div
            style={{
              top: "0",
              width: "100%",
              background: "rgba(0,0,0,0.7)",
              position: "fixed",
              zIndex: "999",
              padding: "5% 20%",
              height: "100%",
            }}>
            <Box color='white' p={1} alignContent='center'>
              <h2 style={{ textAlign: "center" }}>Request A Quote</h2>
              <form
                onSubmit={RequestAQuote}
                style={{ marginTop: "2%", width: "100%", textAlign: "center" }}>
                <div className='checkout_form_tel'>
                  <input type='text' placeholder='Name' />
                </div>
                <div className='checkout_form_tel'>
                  <input type='email' placeholder='Email' />
                </div>
                <div className='checkout_form_tel'>
                  <input type='text' placeholder='Phone Number' />
                </div>
                <div className='checkout_form_tel'>
                  <textarea placeholder='Message'></textarea>
                </div>
                <div className='trend_bottom_btn'>
                  <button
                    style={{ margin: "5px" }}
                    onClick={() => setshowQouteOption(0)}>
                    Cancel
                  </button>
                  <button
                    style={{ margin: "5px" }}
                    onClick={() => handleSlideClick(0)}>
                    {" "}
                    Send Request
                  </button>
                </div>
              </form>
            </Box>
          </div>
        )
      }
      {
        showQouteOption == 3 && (
          <div
            style={{
              top: "0",
              width: "100%",
              background: "rgba(0,0,0,0.7)",
              position: "fixed",
              zIndex: "999",
              padding: "5% 20%",
              height: "100%",
            }}>
            <Box color='white' p={1} alignContent='center'>
              <h2 style={{ textAlign: "center" }}>Request A Sample</h2>
              <form
                onSubmit={RequestAQuote}
                style={{ marginTop: "2%", width: "100%", textAlign: "center" }}>
                <div className='checkout_form_tel'>
                  <input type='text' placeholder='Name' />
                </div>
                <div className='checkout_form_tel'>
                  <input type='email' placeholder='Email' />
                </div>
                <div className='checkout_form_tel'>
                  <input type='text' placeholder='Phone Number' />
                </div>
                <div className='checkout_form_tel'>
                  <textarea placeholder='Message'></textarea>
                </div>
                <div className='trend_bottom_btn'>
                  <button
                    style={{ margin: "5px" }}
                    onClick={() => setshowQouteOption(0)}>
                    Cancel
                  </button>
                  <button
                    style={{ margin: "5px" }}
                    onClick={() => handleSlideClick(0)}>
                    {" "}
                    Send Request
                  </button>
                </div>
              </form>
            </Box>
          </div>
        )
      }

      {/* <Spe_offer /> */}
      <Footer />
      {openCartModal && <CartModel openCartModal={openCartModal} setOpenCartModal={setOpenCartModal} />}

    </div >
  );
};
