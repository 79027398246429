// Products.js
import React, { useState, useEffect } from "react";
import { Footer } from "../components/Footer.js";
import { Slide_title } from "../components/slide-title.js";
import { Spe_offer } from "../components/spe_offer.js";
import LazyLoad from "react-lazy-load";
import filledHeartImage from "./../assets/img/heart.svg";
import unfilledHeartImage from "./../assets/img/heartunfilled.svg";
import loaderImage from "../assets/img/homeimg/loader.gif";
import { Link, useParams } from "react-router-dom";
import {
    product_list_category_wise,
    wishlist_api,
    wishlist_remove_api,
    get_wishlist,
    category_details,
    new_arrivals
} from "../service/api.js";
import Head_CateHead from "../components/Head_CateHead.js";
import { Pagination } from "@mui/material";

export const CategoryListing = () => {
    const { slug } = useParams();
    const [page, setPage] = useState(1);
    const [totalPages, settotalPages] = useState(0);

    useEffect(() => {
        setProductArray([]);
        setPage(1);
        // fetchData();
        fetchAllCate();
    }, [slug])
    var id = '';
    const [productArray, setProductArray] = useState([]);
    const [isAuthenticated, setIsAuthenticated] = useState(
        !!localStorage.getItem("token")
    );
    const [wishlistProductId, setWishlistProductId] = useState([])
    const [shouldReload, setShouldReload] = useState(false);

    const getUserWishlist = async () => {
        try {
            const res = await get_wishlist();
            const wishListId = res.data?.Data?.map((item) => {
                return item.ProductID
            })
            setWishlistProductId([...wishListId])
        } catch (error) {
            console.log(error)
        }
    }
    const [title, setTitle] = useState("")
    const [description, setdescription] = useState("")
    const [bannerimage, setbannerimage] = useState("")
    // useEffect(() => {
    //     if (productArray == []) {
    //         setPage(1);
    //     }
    //  }, [productArray])

    const fetchAllCate = async () => {
        try {
            const res = await category_details(slug);
            if (res.data.Data[0].CategoryName) {
                setTitle(res.data.Data[0].CategoryName)
                setdescription(res.data.Data[0].Description)
                setbannerimage(res.data.Data[0].CategoryBanner)
                fetchData();
            }
        } catch (error) {
            console.log(error)
        }
    }

    const fetchData = async () => {
        try {
            if (slug === "new") {
                const response = await new_arrivals();
                if (response.status === 200) {
                    setTitle('New')
                    setbannerimage('')
                    updateProductData(slug, response.data.Data);
                    settotalPages(response.data.totalPages);
                    getUserWishlist();
                    setPage((prevPage) => prevPage + 1);
                }
            } else if (slug === "indent") {
                const response = await product_list_category_wise(slug, page, 24);
                if (response.status === 200) {
                    updateProductData(slug, response.data.Data);
                    getUserWishlist();
                    settotalPages(response.data.totalPages);
                    setPage((prevPage) => prevPage + 1);
                }
            } else if (slug === "sale") {
                const response = await product_list_category_wise(slug, page, 24);
                if (response.status === 200) {
                    updateProductData(slug, response.data.Data);
                    getUserWishlist();
                    settotalPages(response.data.totalPages);
                    setPage((prevPage) => prevPage + 1);
                }
            } else {
                const response = await product_list_category_wise(slug, page, 24);
                if (response.status === 200) {
                    updateProductData(slug, response.data.Data);
                    getUserWishlist();
                    settotalPages(response.data.totalPages);
                    setPage((prevPage) => prevPage + 1);
                }
            }
        } catch (error) {
            console.error("Error fetching product data:", error);
        } finally {
        }
    };



    const updateProductData = async (slug, filteredData) => {
        try {
            const res = await product_list_category_wise(slug, page, 24);
            const producdataArray = res.data.Data;
            const updatedProductArray = filteredData || producdataArray;
            console.log(updatedProductArray);
            setProductArray(updatedProductArray);
            window.scrollTo(0, 0);
        } catch (error) {
            console.error(error);
        }
    };


    const [isLoading, setIsLoading] = useState(false);

    const toggleWishlist = async (ProductID, UserProductWishlist) => {
        try {
            setIsLoading(true);
            let response;
            const checkWish = wishlistProductId.find((userWish) => userWish === ProductID)

            if (!checkWish) {
                console.log("Adding product to wishlist:", ProductID);
                response = await wishlist_api(ProductID); // Add to wishlist

                if (response.status === 200) {
                    const updatedProductArray = productArray.map((product) =>
                        product.ProductID === ProductID
                            ? {
                                ...product,
                                UserProductWishlist: [response.data.UserProductWishlist], // Add to wishlist
                            }
                            : product
                    );

                    setProductArray(updatedProductArray);
                }
            } else {
                console.log("Removing product from wishlist:", ProductID);
                response = await wishlist_remove_api(ProductID); // Remove from wishlist

                if (response.status === 200) {
                    const updatedProductArray = productArray.map((product) =>
                        product.ProductID === ProductID
                            ? {
                                ...product,
                                UserProductWishlist: [], // Remove from wishlist
                            }
                            : product
                    );

                    setProductArray(updatedProductArray);
                }
            }
          getUserWishlist()
            setIsLoading(false);
        } catch (error) {
            console.error("Error toggling wishlist:", error);
            setIsLoading(false);
        }
    };

    const handlePageChange = async (event, page) => {
        try {
            if (slug === "new") {
                const response = await new_arrivals();
                if (response.status === 200) {
                    updateProductData(slug, response.data.Data);
                    getUserWishlist();
                    setPage(page);
                }
            } else if (slug === "indent") {
                const response = await product_list_category_wise(slug, page, 24);
                if (response.status === 200) {
                    updateProductData(slug, response.data.Data);
                    getUserWishlist();
                    setPage(page);
                }
            } else if (slug === "sale") {
                const response = await product_list_category_wise(slug, page, 24);
                if (response.status === 200) {
                    updateProductData(slug, response.data.Data);
                    getUserWishlist();
                    setPage(page);
                }
            } else {
                const response = await product_list_category_wise(slug, page, 24);
                if (response.status === 200) {
                    updateProductData(slug, response.data.Data);
                    getUserWishlist();
                    setPage(page);
                }
            }
        } catch (error) {
            console.error("Error fetching product data:", error);
        } finally {
        }
    };



    return (
        <div>
            <Head_CateHead />
            <div className="collection_wrapper">
                {bannerimage == null || bannerimage == '' ?
                    <div style={{ position: 'relative', height: '250px', background: '#F2F9FB', marginBottom: '40px' }}>
                        <h1 style={{ textAlign: 'center', paddingTop: '80px', color: '#01AEF0', fontSize: '3.5vw' }}>{title}</h1>
                        <span style={{ color: '#DFF3FA', position: 'absolute', width: "300px", left: '80%', top: '-30%', fontSize: '10.5vw', fontFamily: 'poppins', fontWeight: '600', height: "300px", overflow: "hidden" }}>{title}</span>
                        <span style={{ color: '#DFF3FA', position: 'absolute', right: '83%', bottom: '-14%', fontSize: '10.5vw', fontFamily: 'poppins', fontWeight: '600', height: "260px", overflow: "hidden" }}>{title}</span>
                    </div>
                    :
                    <img style={{ width: '100%', marginBottom: '40px' }} src={process.env.REACT_APP_API_URL + bannerimage} />
                }
                <div>

                </div>
                <div className="container" style={{ height: 'auto', overflow: 'auto' }}>
                    {isLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
                            <img src={loaderImage} alt="Loading..." width="50vw" />
                        </div>
                    ) : (
                        <div className="products_list">
                            {productArray.map((product, index) => (
                                <LazyLoad key={index} offsetVertical={200}>
                                    <Link to={`/product/${product.Slug}`} key={index}>
                                        <div className="arrivals_box" key={index}>
                                            <div className="arrivals_img">
                                                <div className="product_head">
                                                    {isAuthenticated && (
                                                        <button
                                                            className="product_save"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                toggleWishlist(
                                                                    product.ProductID,
                                                                    product.UserProductWishlist
                                                                );
                                                            }}
                                                        >

                                                            <img
                                                                src={wishlistProductId.find((wishPro) => wishPro === product.ProductID)
                                                                    ? filledHeartImage // Show unfilled heart for products not in the wishlist
                                                                    : unfilledHeartImage // Show filled heart for products in the wishlist
                                                                }
                                                                alt="Heart Icon"
                                                            />
                                                        </button>
                                                    )}
                                                </div>
                                                <img
                                                    src={
                                                        process.env.REACT_APP_API_URL + product.ProductImage ||
                                                        process.env.REACT_APP_API_URL + "default.jpg"
                                                    }
                                                    alt="Product"
                                                    className="pro_hero_img"
                                                />
                                            </div>
                                            <div className="arrivals_content">
                                                <div className="arrivals_content_title">
                                                    <h3>{product.ProductName}</h3>
                                                    <p className="sku" style={{ marginLeft: '1', fontSize: '0.83vw', color: '#252525' }}>SKU: {product.SKU}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </LazyLoad>
                            ))}
                        </div>
                    )}
                    {totalPages > 1 && (
                        <div className="trend_pagination" style={{ display: 'block', position: 'relative', paddingBottom: '100px', paddingTop: '50px' }}>
                            <Pagination count={totalPages} shape="rounded" onChange={handlePageChange} />
                        </div>
                    )}
                </div>
            </div>
            {/* <Spe_offer /> */}
            <Footer />
        </div>
    );
};
