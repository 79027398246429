import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
    Products_list_api,
    wishlist_api,
    wishlist_remove_api,
    get_wishlist,
    subcategory_details,
    category_details,
    new_arrivals,
} from "../service/api.js";
import Head_CateHead from "../components/Head_CateHead.js";
import { Slide_title } from "../components/slide-title.js";
import { Spe_offer } from "../components/spe_offer.js";
import { Footer } from "../components/Footer.js";
import filledHeartImage from "./../assets/img/heart.svg";
import unfilledHeartImage from "./../assets/img/heartunfilled.svg";
import loaderImage from "./../assets/img/Spinner.gif";
import { Pagination } from "@mui/material";

export const Products = () => {
    const { categoryslug, subcategoryslug } = useParams();
    let id = 0;

    const [productArray, setProductArray] = useState([]);
    const [selectedSortingOptions, setSelectedSortingOptions] = useState("default");
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem("token"));
    const [wishlistProductId, setWishlistProductId] = useState([]);

    const [title, setTitle] = useState("");
    const [description, setdescription] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [bannerimage, setbannerimage] = useState("")
    const [totalPages, settotalPages] = useState(0);

    useEffect(() => {
        fetchAllCate();
    }, [pageNumber, categoryslug, subcategoryslug]);

    useEffect(() => {
        setProductArray([]);
        setPageNumber(1);
        console.log("productArray", productArray)
    }, [categoryslug, subcategoryslug]);

    const fetchAllCate = async () => {
        try {
            const res2 = await category_details(categoryslug);
            const categoryId = res2.data.Data[0].CategoryID;

            const res = await subcategory_details(categoryId, subcategoryslug);
            if (res.data.Data[0].SubCategoryName) {
                id = res.data.Data[0].SubCategoryID;
                setTitle(res.data.Data[0].SubCategoryName);
                setdescription(res.data.Data[0].Description);
                setbannerimage(res.data.Data[0].SubCategoryBanner)
                fetchData();
            }
        } catch (error) {
            fetchData();
            console.log(error);
        }
    };

    const fetchData = async () => {
        try {
            let response;

            if (subcategoryslug === "new") {
                response = await new_arrivals(pageNumber);
                setTitle("New");
            } else {
                const productData = {
                    SubCategoryID: id,
                    Sort: selectedSortingOptions,
                    RecordsPerPage: 24,
                    PageNo: pageNumber,
                };
                response = await Products_list_api(productData);
            }

            if (response.status === 200) {
                console.log(response);
                updateProductData(id, response.data.data);
                console.log(response.data.totalPages);
                settotalPages(response.data.totalPages);
                getUserWishlist();
            }
        } catch (error) {
            console.error("Error fetching product data:", error);
        }
    };

    const updateProductData = async (id, filteredData, append = false) => {
        console.log("id" ,id )
        console.log("filteredData" ,filteredData )
        console.log("append" ,append )
        console.log("pagenumber" , pageNumber)
        try {
            const res = await Products_list_api({
                SubCategoryID: id,
                Sort: selectedSortingOptions,
                RecordsPerPage: 24,
                PageNo: pageNumber,
            });

            const producdataArray = res.data.Data;
            console.log("producdataArray",producdataArray)
            const updatedProductArray = append
                ? [...productArray, ...producdataArray]
                : filteredData || producdataArray;
            setProductArray(updatedProductArray);
            console.log("updated product array" , updatedProductArray)
        } catch (error) {
            console.error(error);
        }
    };

    const getUserWishlist = async () => {
        try {
            const res = await get_wishlist();
            const wishListId = res.data?.Data?.map((item) => item.ProductID);
            setWishlistProductId([...wishListId]);
        } catch (error) {
            console.log(error);
        }
    };

    const toggleWishlist = async (ProductID, UserProductWishlist) => {
        try {
            setIsLoading(true);
            let response;
            const checkWish = wishlistProductId.find((userWish) => userWish === ProductID);
    
            if (!checkWish) {
                response = await wishlist_api(ProductID);
    
                if (response.status === 200) {
                    const updatedProductArray = productArray.map((product) =>
                        product.ProductID === ProductID
                            ? {
                                  ...product,
                                  UserProductWishlist: [response.data.UserProductWishlist],
                              }
                            : product
                    );
    
                    setProductArray(updatedProductArray);
                }
            } else {
                response = await wishlist_remove_api(ProductID);
    
                if (response.status === 200) {
                    const updatedProductArray = productArray.map((product) =>
                        product.ProductID === ProductID
                            ? {
                                  ...product,
                                  UserProductWishlist: [],
                              }
                            : product
                    );
    
                    setProductArray(updatedProductArray);
                }
            }
            getUserWishlist()
            setIsLoading(false);
        } catch (error) {
            console.error("Error toggling wishlist:", error);
            setIsLoading(false);
        }
    };
    const handlePageChange = async (event, page) => {
        try {
            let response;
            if (subcategoryslug === "new") {
                response = await new_arrivals(page);
            } else {
                const res2 = await category_details(categoryslug);
                console.log(res2);
                const categoryId = res2.data.Data[0].CategoryID;
                const res = await subcategory_details(categoryId, subcategoryslug);
                console.log(subcategoryslug + 12);
                if (res.data.Data[0].SubCategoryName) {
                    id = res.data.Data[0].SubCategoryID;
                }
                console.log(id);
                const productData = {
                    SubCategoryID: id,
                    Sort: selectedSortingOptions,
                    RecordsPerPage: 24,
                    PageNo: page,
                };
                response = await Products_list_api(productData);
            }

            if (response.status === 200) {
                console.log(response);
                updateProductData(id, response.data.data);
                console.log(response.data.totalPages);
                console.log(page);
                settotalPages(response.data.totalPages);
                // getUserWishlist();
                window.scrollTo(0, 0);
                setPageNumber(page);

            }
        } catch (error) {
            console.error("Error fetching product data:", error);
        }
    }
     {/* <div style={{ position: 'relative', height: '250px', background: '#F2F9FB', marginBottom: '40px' }}>
                        <h1 style={{ textAlign: 'center', paddingTop: '80px', color: '#01AEF0', fontSize: '3.5vw' }}> <span dangerouslySetInnerHTML={{ __html: title }} /></h1>
                        <span style={{ color: '#DFF3FA', position: 'absolute', left: '83%', top: '-30%', fontSize: '10.5vw', fontFamily: 'poppins', fontWeight: '600' , height:"300px" , overflow:"hidden" }}><span dangerouslySetInnerHTML={{ __html: title }} /></span>
                        <span style={{ color: '#DFF3FA', position: 'absolute', right: '83%', bottom: '-14%', fontSize: '10.5vw', fontFamily: 'poppins', fontWeight: '600' , height:"260px" , overflow:"hidden"  }}><span dangerouslySetInnerHTML={{ __html: title }} /></span>
                    </div> */}
      
    return (
        <div>
            <Head_CateHead />
            <div className="collection_wrapper">
                {bannerimage == null || bannerimage == '' ?
                   
                   <div style={{ position: 'relative', height: '250px', background: '#F2F9FB', marginBottom: '40px' }}>
                        <h1 style={{ textAlign: 'center', paddingTop: '80px', color: '#01AEF0', fontSize: '3.5vw' }} dangerouslySetInnerHTML={{ __html: title }} />
                        <span style={{ color: '#DFF3FA', position: 'absolute',width:"300px", left: '80%', top: '-30%', fontSize: '10.5vw', fontFamily: 'poppins', fontWeight: '600' , height:"300px" , overflow:"hidden" }} dangerouslySetInnerHTML={{ __html: title }} />
                        <span style={{ color: '#DFF3FA', position: 'absolute', right: '83%', bottom: '-14%', fontSize: '10.5vw', fontFamily: 'poppins', fontWeight: '600' , height:"260px" , overflow:"hidden"  }} dangerouslySetInnerHTML={{ __html: title }} />
                    </div>
                    :
                    <img style={{ width: '100%', marginBottom: '40px' }} src={process.env.REACT_APP_API_URL + bannerimage} />
                }
                <div className="container">
                    {isLoading ? (
                        <div style={{  display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                            <img src={loaderImage} alt="Loading..." />
                        </div>
                    ) : (
                        <div className="products_list">
                            {productArray.map((product, index) => (
                                <Link to={`/product/${product.Slug}`} key={index}>
                                    <div className="arrivals_box" key={index}>
                                        <div className="arrivals_img">
                                            <div className="product_head">
                                                {isAuthenticated && (
                                                    <button
                                                        className="product_save"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            toggleWishlist(product.ProductID, product.UserProductWishlist);
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                wishlistProductId.find((wishPro) => wishPro === product.ProductID)
                                                                    ? filledHeartImage
                                                                    : unfilledHeartImage
                                                            }
                                                            alt="Heart Icon"
                                                        />
                                                    </button>
                                                )}
                                            </div>
                                            <img
                                                src={
                                                    process.env.REACT_APP_API_URL + product.ProductImage ||
                                                    process.env.REACT_APP_API_URL + "default.jpg"
                                                }
                                                alt="Product"
                                                className="pro_hero_img"
                                            />
                                        </div>
                                        <div className="arrivals_content">
                                            <div className="arrivals_content_title">
                                                <h3>{product.ProductName}</h3>
                                                <p className="sku" style={{ marginLeft: "1", fontSize: "0.83vw", color: "#252525" }}>
                                                    SKU: {product.SKU}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    )}
                    {totalPages > 1 && (
                        <div className="trend_pagination" style={{ display: 'block', position: 'relative', paddingBottom: '100px', paddingTop: '50px' }}>
                            <Pagination count={totalPages} shape="rounded" onChange={handlePageChange} />
                        </div>
                    )}
                </div>
            </div>
            {/* <Spe_offer /> */}
            <Footer />
        </div>
    );
};

